import React, { useRef, useEffect } from 'react';

import { navigate } from 'gatsby';
import { bool } from 'prop-types';
import { useLocation } from '@reach/router';

import { BiDownload, BiArrowBack } from 'react-icons/bi';
import { Box, Text, Center, Flex, Divider, useToast, Button } from '@chakra-ui/react';

import QRCode from 'react-qr-code';
import loadable from '@loadable/component';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

import useLang from '@/hooks/useLang';
import useGuestInfo from '@/hooks/useGuestInfo';

import { THE_BRIDE, WEDDING_DATE } from '@/constants';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

// @important - please do don't change to CDN github
// we will got failed export image because CORS issue
import IMG_QR_COVER from '@/assets/bg/eticket.jpg';

import txt from '../locales';
import QRButton from '../../QRCodeButton';

const LazyExportPNG = loadable(() =>
  import(/* webpackChunkName: "invitato-export-png" */ '../ExportPNG'),
);

/**
 * Function to render QRCard version 2
 * Support:
 * - Download QR as PNG
 * - Open QR in new page
 */
function QRCardSection({ isDirectLink, ...rest }) {
  const toast = useToast();
  const location = useLocation();
  const isAutoDownload = getQueryValue(location, 'autoDownload') == '1';

  const lang = useLang();
  const ticketRef = useRef(null);
  const isShowToaster = useRef(false);
  const { code, name, desc } = useGuestInfo();

  // IF code is not found, just ignore it
  if (!code) return null;

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !isDirectLink &&
      !isShowToaster.current &&
      !isAutoDownload
    ) {
      isShowToaster.current = true;
      toast({
        isClosable: true,
        status: 'warning',
        duration: null,
        description: (
          <Text fontSize="sm">
            <span dangerouslySetInnerHTML={{__html: txt.darkmodeDesc[lang]}} />
          </Text>
        ),
      });
    }
  }, []);

  return (
    <Box bgColor="bgPrimary" padding="16px 0" {...rest}>
      <div ref={ticketRef}>
        <Box bgColor="bgPrimary" padding="6px">
          <Box bgColor="white" padding="6px">
            {/* QRCODE COVER SECTION */}
            <Box
              bgImage={`url(${IMG_QR_COVER})`}
              width="100%"
              height="200px"
              bgSize="cover"
              bgPos="center"
              boxShadow="inset 0 0 0 2000px rgb(0 0 0 /10%)"
            >
              <Center height="200px" fontFamily="jost">
                <Box color="mainColorText" textTransform="uppercase" textAlign="center" textShadow="1px 1px 2px #7a7979">
                  <Text fontSize="xs" letterSpacing="2px" marginTop="12px">
                    {txt.title[lang]}
                  </Text>
                  <Text fontSize="xl" letterSpacing="6px" margin="4px 0">
                    {THE_BRIDE}
                  </Text>
                  <Text fontSize="xs" letterSpacing="4px" fontWeight="light">
                    {WEDDING_DATE}
                  </Text>
                </Box>
              </Center>
            </Box>
            {/* DETAIL QRCODE */}
            <Flex fontFamily="jost" fontSize="sm" padding="12px 4px" justifyContent="space-between">
              <Box flex="1.3" fontSize="xs">
                <Text>{txt.to[lang]}</Text>
                <Text fontWeight="normal">{name}</Text>
                
                <Text marginTop="8px">{txt.desc[lang]}</Text>
                <Text fontWeight="normal">{desc || '-'}</Text>
                
                <Text marginTop="8px">{txt.important[lang]}</Text>
                <Text fontWeight="normal">{txt.importantDesc[lang]}</Text>
              </Box>
              <Box flex="1">
                <Box float="right">
                  <QRCode value={code} size={125} />
                </Box>
              </Box>
            </Flex>
          </Box>
          <Divider opacity="1" height="2px" bgColor="bgPrimary" border="none" />
          {/* Copyright Section */}
          <Box fontFamily="jost" textAlign="center" bgColor="white" padding="8px 0">
            <Text fontSize="xs">Organized & Generated by Invitato</Text>
            <Text fontSize="xs">{`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}</Text>
          </Box>
        </Box>
      </div>
      <Box color="mainColorText">
        <Text fontSize="sm" textAlign="center" margin="8px">
            <span dangerouslySetInnerHTML={{__html: txt.downloadDesc[lang] }}/>
        </Text>
        <Center>
          {isDirectLink ? (
            <QRButton
              text="Download E-ticket"
              customQueries="&autoDownload=1"
              color="btnMainColor"
              bgColor="bgSecondary"
              padding="8px"
              size="sm"
              fontWeight="normal"
              marginBottom="12px"
              {...DEFAULT_BUTTON_PROPS}
            />
          ) : (
            <LazyExportPNG
              componentRef={ticketRef}
              autoDownload={isAutoDownload}
              marginTop="16px"
              leftIcon={<BiDownload />}
              type="button"
              size="sm"
              fontWeight="normal"
              color="btnMainColor"
              bgColor="bgSecondary"
              {...DEFAULT_BUTTON_PROPS}
            />
          )}
        </Center>
        {!isDirectLink && (
          <Center>
            <Button
              marginTop="16px"
              leftIcon={<BiArrowBack />}
              type="button"
              size="sm"
              fontWeight="normal"
              color="btnMainColor"
              bgColor="bgSecondary"
              onClick={() => navigate(-1)}
              {...DEFAULT_BUTTON_PROPS}
            >
              {txt.back[lang]}
            </Button>
          </Center>
        )}
      </Box>
    </Box>
  );
}

QRCardSection.propTypes = {
  isDirectLink: bool,
};

QRCardSection.defaultProps = {
  isDirectLink: true,
};

export default React.memo(QRCardSection);